import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {StateService} from "../core/state.service";
import {EventType} from "./event-type";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    public readonly httpClient: HttpClient,
    public readonly stateService: StateService,
  ) {
  }

  public createEvent(type: EventType, metadataDescription?: string) {
    this.httpClient.post(environment.apiUrlNew + '/event', {
        type: type,
        promptUuid: this.stateService.result?.uuid,
        metadata: {
          description: metadataDescription || ''
        }
      }
    ).subscribe({});
  }
}
