import {Injectable} from '@angular/core';
import {SportsEvent, PromptResult, BettingLine, BettingMarket} from "../capture/capture.service";
import {UserInfo} from "../userInfo";

@Injectable({
  providedIn: 'root'
})
export class StateService {
  // include registerLocaleData in app.module when expanding this list
  public static readonly supportedLanguages = ['it', 'en', 'de', 'es', 'fr', 'xx'];
  theme: 'goldbet' | 'better' | 'promptbet' | 'betflag' | 'bet2day' = 'promptbet';
  clientLogo = 'assets/logos/promptbet.png';
  languages = StateService.supportedLanguages;
  isNative = false;
  submitted = false;
  testPrompt?: string;

  token?: string;
  user?: UserInfo;

  selection: any[] = []; // { [key: string]: any, id: string }
  result!: PromptResult;

  selectedMatch?: SportsEvent;
  previousPromptResult?: PromptResult;

  constructor() {
  }

  public setResult(res: PromptResult, othersLabel: string) {
    const sorter = (s1: BettingLine, s2: BettingLine) => {
      const index1 = s1.externalMetadata?.selectionId ? +s1.externalMetadata?.selectionId : 998;
      const index2 = s2.externalMetadata?.selectionId ? +s2.externalMetadata?.selectionId : 999;
      return index1 - index2;
    };
    res.events.forEach(event => {
      event.markets.forEach(this.processMarkets(sorter, othersLabel));
      event.markets.sort(this.sortMarkets());
      event.comboMarkets.forEach(this.processMarkets(sorter, othersLabel));
      event.comboMarkets.sort(this.sortMarkets());

      if (event.externalMetadata?.extendedInfo) {
        event.externalMetadata = {
          ...event.externalMetadata,
          //extendedInfo: undefined,
          ...JSON.parse(event.externalMetadata.extendedInfo)
        };
      }
    });
    res.events.sort((m1, m2) => {
      return new Date(m1.startTs).getTime() - new Date(m2.startTs).getTime();
    });
    this.result = res;
  }

  private sortMarkets() {
    return (a: BettingMarket, b: BettingMarket) => {
      if (a.bettingMarketType === b.bettingMarketType) {
        return +a.externalMetadata?.marketId - +a.externalMetadata?.marketId;
      }
      return 0;
    };
  }

  private processMarkets(sorter: (s1: BettingLine, s2: BettingLine) => number, othersLabel: string) {
    return (m: BettingMarket) => {
      m.lines.sort(sorter);
      m.lines.forEach(line => {
        if (line.externalMetadata?.extendedInfo) {
          line.externalMetadata = {
            ...line.externalMetadata,
            //extendedInfo: undefined,
            ...JSON.parse(line.externalMetadata.extendedInfo)
          };
        }
        if (line.name?.toLowerCase() === 'others') {
          line.name = othersLabel;
        }
      });
      m.previewLines = m.lines.slice(0, 6);
      if (m.externalMetadata?.extendedInfo) {
        m.externalMetadata = {
          ...m.externalMetadata,
          //extendedInfo: undefined,
          ...JSON.parse(m.externalMetadata.extendedInfo)
        };
      }
    };
  }

  isSelected(id: string) {
    return this.selection.some(sel => sel.id === id);
  }
}

