import {ActivatedRouteSnapshot, CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {StateService} from "./core/state.service";
import {environment} from "../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CommunicationService} from "./core/communication.service";
import {ThemeService} from "./core/theme.service";
import {UserInfo} from "./userInfo";

export const consentGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
) => {

  const stateService = inject(StateService);
  const communicationService = inject(CommunicationService);
  const httpClient = inject(HttpClient);
  const themeService = inject(ThemeService);
  let router = inject(Router);

  if (stateService.user) {
    return true;
  }

  const anonymousParam = route.queryParamMap.get('anonymous') === 'true';
  let queryParamUserId = route.queryParamMap.get('userId');
  let queryParamLanguage = (route.queryParamMap.get('language') || '').toLowerCase();
  queryParamUserId = queryParamUserId === 'null' || queryParamUserId === '0' ? null : queryParamUserId;

  stateService.token = route.queryParamMap.get('token') || undefined;
  stateService.isNative = route.queryParamMap.get('isNative') === 'true';
  stateService.testPrompt = route.queryParamMap.get('testPrompt') || undefined;

  communicationService.logToApp('isNative: ' + stateService.isNative);
  communicationService.logToApp('token: ' + stateService.token);
  communicationService.logToApp('testPrompt: ' + stateService.testPrompt);

  async function fetchUserDetails(): Promise<UserInfo | undefined> {
    const res = await httpClient.get<UserInfo>(environment.apiUrlNew + '/user').toPromise();
    console.log('User fetched ', res);
    communicationService.logToApp('User fetched, id: ' + res?.externalId);
    httpClient.patch<UserInfo>(environment.apiUrlNew + '/user/' + res?.uuid + '/session', {
      nativeApp: stateService.isNative
    }).subscribe();
    return res;
  }

  async function createTestUser() {
    return await httpClient.post<{ token: string }>(environment.apiUrlNew + '/client/activate/user',
      {
        externalId: queryParamUserId || ('test-' + new Date().toISOString().split('T')[0]),
        test: true,
        anonymous: anonymousParam
      },
      {
        headers: new HttpHeaders({
          'X-Client-Secret': environment.testClientSecret
        })
      }).toPromise();
  }
  if (!stateService.user) {
    if (stateService.token) {
      stateService.user = await fetchUserDetails();
    } else {
      const tokenRes = await createTestUser();
      if (tokenRes) {
        stateService.token = tokenRes.token;
        stateService.user = await fetchUserDetails();
      }
    }
  }

  themeService.injectTheme();
  document.querySelector('#app-loading-spinner')?.remove();

  if (queryParamLanguage && StateService.supportedLanguages.includes(queryParamLanguage)) {
    stateService.user!.language = queryParamLanguage;
    httpClient.patch<{ data: any }>(environment.apiUrlNew + '/user/language', {
      language: queryParamLanguage
    }).subscribe();
  }

  if (!stateService.user?.termsAcceptedTs || anonymousParam || stateService.user?.anonymous) {
    return router.navigate(['/consent']);
  }
  return true;
};
